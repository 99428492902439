* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Lexend Deca", sans-serif;
}

li {
  list-style: none;
  /* display: inline-block; */
}

a {
  text-decoration: none;
  /* display: inline-block; */
  letter-spacing: 0.2px;
}

section {
  border-bottom: 2px solid #e6e6e6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
