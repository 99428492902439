.card-container {
  display: flex;
  flex-direction: column;
  gap: 9.6rem;
  width: 80%;
  margin: 0 auto;
}

.section-title {
  font-size: 3.6rem;
  text-transform: uppercase;
  letter-spacing: -1.2px;
  margin: 0 auto 9.6rem;
  display: flex;
  align-items: flex-end;
  gap: 1.2rem;
  white-space: nowrap;
}

.section-title::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  margin-bottom: 0.9rem;
  background-color: #262626ff;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.6rem;
}

.about-card-content {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.4;
  text-align: left;
  color: #ffffff;
  word-spacing: 2px;
}

.line {
  display: block;
  height: 2px;
  width: 100%;
  background-color: #e6e6e6;
}

.card-number-image {
  width: 16rem;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3.2rem;
  margin-top: 12.8rem;
}

.card {
  min-width: 25rem;
  max-width: 28rem;
  outline: 2px solid #e6e6e6;
  border-radius: 1rem;
  display: grid;
  padding: 3.2rem;
  cursor: pointer;
}
.card:hover {
  transition: 0.2s ease-out;
  transform: scale(1.02) translate(0, -2%);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.card img {
  width: auto;
  height: 8rem;
  margin: 0 auto;
}

.cards h4 {
  font-size: 2rem;
  text-align: center;
  padding: 1.6rem 0 0.4rem;
}

.about-card-content-secondary {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.8rem;
  text-align: center;
  color: #808897ff;
}

@media (max-width: 992px) {
  .section-title {
    font-size: 3rem;
    margin: 0 auto 8rem;
  }
  .row {
    gap: 2.4rem;
  }
  .about-card-content {
    font-size: 1.6rem;
    line-height: 1.4;
    word-spacing: 2px;
  }

  .card-number-image {
    width: 14rem;
  }

  .cards {
    margin-top: 9.6rem;
  }
  .card img {
    height: 7rem;
  }
  .cards h4 {
    font-size: 1.8rem;
    padding: 1.4rem 0 0.2rem;
  }
  .about-card-content-secondary {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

@media (max-width: 786px) {
  .section-title {
    font-size: 2.4rem;
    margin: 0 auto 6.4rem;
  }
  .row {
    gap: 1.2rem;
  }
  .card-container {
    width: 90%;
  }

  .card-number-image {
    width: 10rem;
  }

  .card img {
    height: 7rem;
  }
  .cards h4 {
    font-size: 1.8rem;
    padding: 1.4rem 0 0.2rem;
  }
  .about-card-content-secondary {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@media (max-width: 576px) {
  .card-container {
    gap: 6.4rem;
    width: 100%;
  }
  .section-title::before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    margin-bottom: 0.9rem;
    background-color: #262626ff;
  }
  .row {
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem;
  }

  .card-number-image {
    width: 10rem;
    display: flex;
    align-items: center;
  }
  .container-image {
    display: flex;
    width: 100%;
    gap: 1.2rem;
    align-items: center;
  }
  .container-image::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #e6e6e6;
  }
  .container-image::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #e6e6e6;
  }

  .about-card-content {
    text-align: center;
  }
  .cards {
    margin-top: 8rem;
  }
  .card {
    max-width: 25rem;
  }
}

@media (max-width: 367px) {
  .card-container {
    width: 100%;
  }
}
