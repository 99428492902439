.container {
  max-width: 120rem;
  margin: 0rem auto;
  padding: 12.8rem 6.4rem;
}

@media (max-width: 992px) {
  .container {
    margin: 0rem auto;
    padding: 9.6rem 4.8rem;
  }
}

@media (max-width: 786px) {
  .container {
    margin: 0 auto;
    padding: 8rem 4.8rem;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 8rem 4rem;
  }
}

@media (max-width: 415px) {
  .container {
    padding: 8rem 3.2rem;
  }
}

@media (max-width: 367px) {
  .container {
    padding: 8rem 2.4rem;
  }
}
