.card-about {
  max-width: 40rem;
  background: linear-gradient(
    90deg,
    rgba(18, 100, 219, 1) 0%,
    rgba(63, 153, 255, 1) 100%
  );
  padding: 2.4rem 4.8rem;
  border-radius: 1rem;
}

@media (max-width: 786px) {
  .card-about {
    padding: 2.4rem 2.4em;
  }
}

@media (max-width: 576px) {
  .card-about {
    /* margin: 0 auto; */
    max-width: 100%;
  }
}
