header {
  height: 7rem;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  display: block;
  top: 0;
  background-color: #ffffffff;
  z-index: 1;
  user-select: none;
}

.container {
  max-width: 160rem;
  margin: 0 auto;
  padding: 0 6.4rem;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7rem;
  background-color: #ffffff;
}
.logo {
  height: 100%;
}
img {
  height: 100%;
}
.nav-lists {
  width: 30rem;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
nav ul li a {
  font-size: 1.8rem;
  height: 7rem;
  padding: 0 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #262626ff;
  font-weight: 400;
  transition: 0.1s ease-out;
}
nav ul li a:hover {
  font-weight: 400;
  background-color: #262626ff;
  color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.company-logo {
  padding: 1.2rem 0;
}

@media (max-width: 576px) {
}

@media (max-width: 992px) {
  header {
    height: 6.5rem;
  }
  nav {
    height: 6.5rem;
  }
  nav ul li a {
    font-size: 1.4rem;
    height: 6.5rem;
    padding: 0 2.4rem;
  }
  .container {
    padding: 0 4.8rem;
  }
}

@media (max-width: 786px) {
  header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .container {
    max-width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 6.4rem;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .logo {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    margin-left: 4.8rem;
  }
  .icon {
    font-size: 4rem;
    margin-right: 4.8rem;
    position: relative;
    z-index: 5;
  }
  .nav-open {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
  }
  .nav-lists {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .nav-list {
    width: 100vw;
  }
  .nav-list a {
    height: 7rem;
    font-size: 2.4rem;
    padding: 0;
  }
}

@media (max-width: 576px) {
  .logo {
    margin-left: 4rem;
  }
  .icon {
    margin-right: 4rem;
  }
}

@media (max-width: 415px) {
  .logo {
    margin-left: 3.2rem;
  }
  .icon {
    margin-right: 3.2rem;
  }
}

@media (max-width: 367px) {
  .logo {
    margin-left: 2.4rem;
  }
  .icon {
    margin-right: 2.4rem;
  }
}
