.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 6.4rem;
}
.container-secondary {
  padding-top: 9.6rem;
}
.footer {
  background-color: #262626ff;
}

.footer-logo {
  width: 100%;
}
.footer img {
  width: 15rem;
  height: auto;
  background-color: #ffffff;
  padding: 1.2rem;
  border-radius: 0.4rem;
}

.grid-cols-4 {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(4, 1fr);
}

.grid-cols-4 p {
  font-size: 1.6rem;
  color: #ced0d9ff;
  margin-bottom: 1.2rem;
}

.grid-cols-4 ul li a {
  margin-bottom: 0.6rem;
}

.logo-col {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

address {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.nav-link {
  color: #808897ff;
  font-size: 1.4rem;
  display: inline-block;
}

.nav-link:hover {
  color: #ced0d9ff;
  text-underline-offset: 2px;
  text-decoration: underline;
}

.nav-link {
  margin-bottom: 0;
}

.footer .address {
  margin: 0;
  font-style: normal;
  font-size: 1.4rem;
  color: #808897ff;
}
.number a,
.e-mail a {
  display: inline-block;
  font-size: 1.4rem;
  font-style: normal;
}

.footer-social-links {
  display: flex;
  gap: 1.2rem;
}

.icon {
  font-size: 3rem;
}

.container-copyrights {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6rem auto 1rem;
}

.copyrights p,
.privacy-policy a {
  display: inline-block;
  color: #808897ff;
  font-size: 1.2rem;
}

.privacy-policy span {
  color: #808897ff;
}

@media (max-width: 992px) {
  .container-secondary {
    padding-top: 8rem;
  }
  .footer img {
    width: 13rem;
    padding: 1rem;
    border-radius: 0.2rem;
  }
  .grid-cols-4 {
    display: grid;
    gap: 2.4rem;
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-cols-4 ul li a {
    margin-bottom: 0.4rem;
  }
  .logo-col {
    gap: 0.6rem;
  }
  address {
    gap: 0.6rem;
  }

  .footer-social-links {
    gap: 1rem;
  }

  .container-copyrights {
    margin: 1.4rem auto 0.8rem;
  }
}

@media (max-width: 786px) {
  .container {
    padding: 0 4.8rem;
  }
  .container-secondary {
    padding-top: 6.4rem;
  }
  .footer img {
    width: 14rem;
    padding: 1rem;
    border-radius: 0.2rem;
  }
  .grid-cols-4 {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-cols-4 p {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
  .grid-cols-4 ul li a {
    margin-bottom: 0.6rem;
  }
  .logo-col {
    gap: 1rem;
    align-items: start;
  }

  .footer-social-links {
    gap: 1rem;
  }
  .icon {
    font-size: 2.8rem;
  }
  .container-copyrights {
    margin: 1rem auto 0.8rem;
    gap: 1.2rem;
    gap: 3rem;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 0 4rem;
  }

  .container-copyrights {
    flex-direction: column;
    gap: 0.4rem;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 3rem auto 1.2rem;
  }

  .copyrights p,
  .privacy-policy a {
    margin-top: 0rem;
  }
}

@media (max-width: 500px) {
  .grid-cols-4 {
    justify-items: center;
    grid-template-columns: 1fr;
  }
  .grid-cols-4 div {
    text-align: center;
  }
  .container-copyrights {
    align-items: center;
  }
  .copyrights p,
  .privacy-policy a {
    text-align: center;
  }
}
