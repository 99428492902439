.section-title {
  font-size: 3.6rem;
  text-transform: uppercase;
  letter-spacing: -1.2px;
  margin: 0 auto 9.6rem;
  display: flex;
  align-items: flex-end;
  gap: 1.2rem;
  white-space: nowrap;
}

.section-title::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  margin-bottom: 0.9rem;
  background-color: #262626ff;
}

.title {
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -0.8px;
  margin: 0 auto 1.6rem;
  display: flex;
  align-items: flex-end;
  gap: 1.2rem;
  white-space: nowrap;
}

.paragraph {
  font-size: 1.8rem;
  margin-bottom: 4.8rem;
  line-height: 1.6;
  text-align: justify;
}

.link {
  margin-left: 0.6rem;
  text-decoration: underline;
}

.list-item {
  list-style: disc;
  margin-left: 2rem;
  margin-bottom: 1.8rem;
}

@media (max-width: 992px) {
  .section-title {
    font-size: 3rem;
    margin: 0 auto 8rem;
  }

  .title {
    font-size: 2.4rem;
    margin: 0 auto 0.8rem;
  }
  .paragraph {
    font-size: 1.6rem;
    margin-bottom: 3.2rem;
    line-height: 1.6;
    text-align: justify;
  }
}

@media (max-width: 786px) {
  .section-title {
    font-size: 2.4rem;
    margin: 0 auto 6.4rem;
  }

  .title {
    font-size: 2rem;
    margin: 0 auto 0.8rem;
  }
  .paragraph {
    font-size: 1.5rem;
    margin-bottom: 3.2rem;
    line-height: 1.6;
  }
}

@media (max-width: 576px) {
  .section-title {
    font-size: 2.4rem;
  }
  .section-title::before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    margin-bottom: 0.9rem;
    background-color: #262626ff;
  }
  .title {
    justify-content: center;
    white-space: normal;
    text-align: center;
  }
}
