.section-title {
  font-size: 3.6rem;
  text-transform: uppercase;
  letter-spacing: -1.2px;
  margin: 0 auto 9.6rem;
  display: flex;
  align-items: flex-end;
  gap: 1.2rem;
  white-space: nowrap;
}

.section-title::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  margin-bottom: 0.9rem;
  background-color: #262626ff;
}
.container-secondary {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 4.8rem;
}
.form-container {
  flex-basis: 40%;
  flex-shrink: 1;
}
.image-container {
  flex-basis: 60%;
  flex-shrink: 0;
}
.image-container img {
  width: 100%;
}
form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
label {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
}

input,
textarea {
  width: 100%;
  height: 4rem;
  border: none;
  background-color: #e6e6e6;
  border-radius: 0.3rem;
  font-family: inherit;
  font-size: 1.6rem;
  padding: 1.2rem;
  resize: none;
}

input:focus,
textarea:focus {
  background-color: #ffffff;
  outline: 2px solid #187ef2ff;
}

input::placeholder,
textarea::placeholder {
  font-size: 1.4rem;
}

.container-secondary div {
  display: flex;
  flex-direction: column;
}

.btn-submit {
  display: flex;
  width: 13rem;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #1264db 0%, #3f99ff 100%);
  color: #ffffff;
  font-size: 2rem;
  margin-top: 1.2rem;
  border: none;
  font-family: inherit;
  padding: 1.2rem 2.4rem;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: 0.4s ease-out;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.btn-submit:hover {
  transition: none;
  background: #262626ff;
  color: #ffffff;
  outline: 2px solid #3f99ffff;
}
.btn-submit:active {
  transform: scale(0.96);
  color: #3f99ffff;
}

@media (max-width: 992px) {
  .section-title {
    font-size: 3rem;
    margin: 0 auto 8rem;
  }
  .container-secondary {
    gap: 2.4rem;
  }
  .image-container {
    flex-basis: 55%;
  }
  .form-container {
    flex-basis: 45%;
  }
  form {
    gap: 1.6rem;
  }

  input,
  textarea {
    border-radius: 0.2rem;
    font-size: 1.6rem;
  }

  textarea {
    height: 9.6rem !important;
  }
  .btn-submit {
    width: 11rem;
    font-size: 1.8rem;
    margin-top: 1rem;
    padding: 1rem 2rem;
    border-radius: 0.2rem;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 786px) {
  .section-title {
    font-size: 2.4rem;
    margin: 0 auto 6.4rem;
  }
  .container-secondary {
    gap: 1.2rem;
  }
  .form-container {
    flex-basis: 45%;
  }
  .image-container {
    flex-basis: 55%;
  }
  form {
    gap: 1.2rem;
  }

  input,
  textarea {
    height: 3.2rem;
    border-radius: 0.2rem;
    font-size: 1.4rem;
    padding: 0.8rem;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 1.2rem;
  }
  textarea {
    height: 8rem !important;
  }
}

@media (max-width: 576px) {
  .section-title {
    font-size: 2.4rem;
  }
  .section-title::before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    margin-bottom: 0.9rem;
    background-color: #262626ff;
  }
  .container-secondary {
    display: block;

    margin: 0 auto;
  }
  .image-container {
    display: none;
    visibility: hidden;
  }
  .image-container img {
    display: none;
    visibility: hidden;
  }
  form {
    gap: 1.2rem;
  }

  label {
    font-size: 1.8rem;
  }
  input,
  textarea {
    height: 4rem;
    border-radius: 0.2rem;
    font-size: 1.6rem;
    padding: 0.8rem;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 1.4rem;
  }
  textarea {
    height: 10rem !important;
  }
  .btn-submit {
    width: 12rem;
    align-self: center;
    font-size: 2rem;
    margin-top: 2.4rem;
    padding: 1.2rem 2.4rem;
    border-radius: 0.4rem;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }
}
