.flex-container {
  display: flex;
  gap: 9.6rem;
  align-items: center;
  justify-content: center;
}

.content-container {
  width: 50%;
}

img {
  width: 100%;
  height: 100%;
}

.title-primary {
  font-size: 5.2rem;
  line-height: 4.8rem;
  font-weight: 800;
  color: #262626ff;
}
.title-primary > span {
  letter-spacing: -3px;
  text-transform: uppercase;
  margin-left: -0.4rem;
}

.title-secondary {
  color: #808897ff;
  font-size: 3.54rem;
  font-weight: 400;
  margin-top: 0.2rem;
  letter-spacing: -0.5px;
  line-height: 4rem;
}

.btn-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #1264db 0%, #3f99ff 100%);
  color: #ffffff;
  font-size: 2.4rem;
  margin-top: 2.4rem;
  border: none;
  font-family: inherit;
  padding: 1.2rem 2.4rem;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: 0.4s ease-out;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.btn-contact:hover {
  transition: none;
  background: #262626ff;
  color: #ffffff;
  outline: 2px solid #3f99ffff;
}
.btn-contact:active {
  transform: scale(0.96);
  color: #3f99ffff;
}

@media (max-width: 992px) {
  .flex-container {
    gap: 6.4rem;
  }
  .title-primary {
    font-size: 4.4rem;
    line-height: 4rem;
  }

  .title-secondary {
    color: #808897ff;
    font-size: 2.96rem;
    margin-top: 0rem;
  }

  .btn-contact {
    font-size: 2rem;
    margin-top: 2rem;
    padding: 1rem 2rem;
    border-radius: 0.3rem;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 786px) {
  .flex-container {
    gap: 3.2rem;
  }
  .content-container {
    width: 60%;
  }

  .title-primary {
    font-size: 4rem;
    line-height: 3.6rem;
  }

  .title-secondary {
    font-size: 2.66rem;
    white-space: nowrap;
  }

  .btn-contact {
    font-size: 1.8rem;
    margin-top: 2rem;
    padding: 0.8rem 1.6rem;
    border-radius: 0.3rem;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 700px) {
  .flex-container {
    gap: 3.2rem;
  }
  .content-container {
    width: 60%;
  }

  .title-primary {
    font-size: 3.6rem;
    line-height: 3rem;
  }

  .title-secondary {
    font-size: 2.4rem;
    white-space: nowrap;
  }

  .btn-contact {
    font-size: 1.8rem;
    margin-top: 2rem;
    padding: 0.8rem 1.6rem;
    border-radius: 0.3rem;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 576px) {
  .flex-container {
    flex-direction: column;
    gap: 6.4rem;
  }
  .image-container {
    order: 2;
  }
  .content-container {
    width: 100%;
    text-align: center;
    order: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0rem;
  }
  .title-primary {
    font-size: 4.4rem;
    line-height: 4rem;
  }

  .title-secondary {
    font-size: 3rem;
  }

  .btn-contact {
    align-self: center;
    font-size: 2.4rem;
    margin: 2.4rem auto 0;
    padding: 1.2rem 2.4rem;
    border-radius: 0.4rem;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 415px) {
  .title-primary {
    font-size: 4rem;
    line-height: 1;
  }

  .title-secondary {
    font-size: 2.75rem;
  }
}

@media (max-width: 367px) {
  .title-primary {
    font-size: 3.6rem;
  }

  .title-secondary {
    font-size: 2.43rem;
  }
}
