.section-title {
  font-size: 3.6rem;
  text-transform: uppercase;
  letter-spacing: -1.2px;
  margin: 0 auto 9.6rem;
  display: flex;
  align-items: flex-end;
  gap: 1.2rem;
  white-space: nowrap;
}

.section-title::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  margin-bottom: 0.9rem;
  background-color: #262626ff;
}

.tower-buddy-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  width: 90%;
  max-height: 20rem;
  margin: 0 auto;
  border: 3px solid #187ef2ff;
  margin-bottom: 4.8rem;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.tower-buddy-container:hover {
  transition: 0.2s ease-in-out;
  transform: scale(1.05);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.container-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  background-color: #187ef2ff;
  flex-basis: 20%;
}

.container-logo img {
  height: 100%;
  width: auto;
  transform: translateX(-3%);
}

.card-title {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.card-icon {
  font-size: 2.8rem;
  transform: translateY(-4%);
  color: #187ef2ff;
  stroke-width: 0.1px;
}
.card-content {
  display: flex;
  flex-direction: column;

  gap: 0.8rem;
  padding: 2.4rem;
}

.tower-buddy-container .card-content h4 {
  color: #187ef2ff;
  font-size: 3rem;
  letter-spacing: -0.8px;
  font-weight: 600;
}

.card-content p {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.2px;
}

.services-cards {
  display: grid;
  width: 80%;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  cursor: pointer;
}

.service-card::before {
  content: "";
  display: block;
  background-color: #187ef2ff;
  height: 100%;
  width: 1rem;
  position: absolute;
  left: -1%;
  top: 0;
}
.service-card {
  position: relative;
  padding: 2.4rem;
  border: 2px solid #e6e6e6;
  border-radius: 1rem;
  overflow: hidden;
}
.service-card:hover {
  transition: 0.2s ease-in-out;
  transform: scale(1.02);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: #187ef2ff;
}

.services-cards h4 {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin-bottom: 1rem;
}
.services-cards p {
  font-size: 1.6rem;
  font-weight: 300;
  color: #808897ff;
  letter-spacing: 0.4px;
  /* text-align: justify; */
}

@media (max-width: 992px) {
  .section-title {
    font-size: 3rem;
    margin: 0 auto 8rem;
  }
  .tower-buddy-container {
    width: 95%;
  }
  .tower-buddy-container {
    margin-bottom: 4rem;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .tower-buddy-container:hover {
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  }

  .services-cards {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    width: 90%;
  }
  .services-cards h4 {
    font-size: 2rem;
    line-height: 1.2;
  }

  .services-cards p {
    font-size: 1.6rem;
  }
}

@media (max-width: 786px) {
  .section-title {
    font-size: 2.4rem;
    margin: 0 auto 6.4rem;
  }

  .tower-buddy-container {
    margin-bottom: 4rem;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .tower-buddy-container:hover {
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  }

  .tower-buddy-container .card-content h4 {
    font-size: 2.4rem;
    letter-spacing: -0.8px;
  }
  .card-content p {
    font-size: 1.8rem;
  }
  .services-cards {
    width: 90%;
    grid-template-columns: 1fr;
    gap: 2.4rem;
  }
}

@media (max-width: 576px) {
  .section-title::before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    margin-bottom: 0.9rem;
    background-color: #262626ff;
  }

  .tower-buddy-container {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .tower-buddy-container:hover {
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  }

  .tower-buddy-container .card-content h4 {
    font-size: 2.4rem;
    letter-spacing: -0.8px;
  }
}

@media (max-width: 570px) {
  .tower-buddy-container {
    width: 100%;
  }
  .container-logo {
    display: none;
    visibility: hidden;
  }

  .container-logo img {
    display: none;
    visibility: hidden;
    height: 16rem;
    width: auto;
    transform: translateX(-3%);
  }
  .services-cards {
    width: 100%;
  }
}

@media (max-width: 415px) {
  .section-title {
    font-size: 2rem;
  }
}

/* @media (max-width: 367px) {
} */
